.mapboxgl-ctrl-group {
    background-color: var(--neutrals-cool-gray-800, #1E293B) !important;
    padding: 1px;
    color: #FFF !important;
}

.mapboxgl-ctrl-group:has(.mapboxgl-ctrl-geolocate) {
    background-color: var(--neutrals-cool-gray-800, #1E293B) !important;
    height: 30px !important;
    width: 30px !important;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 3px;

    @media screen and (max-width :480px) {
        height: 22px !important;
        width: 22px !important;
    }
}

.mapboxgl-ctrl-group button {
    height: 30px !important;
    width: 30px !important;
    @media screen and (max-width :480px) {
        height: 22px !important;
        width: 22px !important;
    }
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
    background-image: url("../assets/image__icon_.svg");
    background-size: 38px;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
    background-image: url("../assets/plus_.svg");
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
    margin: 5px 10px !important
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
    background-image: url("../assets/line.svg");
}

.mapboxgl-ctrl-group:has(.mapboxgl-ctrl-zoom-in) {
    margin-bottom: 20px !important;
}

.MuiPopover-paper {
    margin-top: 0px !important
}